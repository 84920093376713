import React from 'react';
import './App.scss';
import Card from 'react-bootstrap/Card';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button'
import Toast from 'react-bootstrap/Toast'
import cslogo from './images/contrast-logo.png';
import cat from './images/avatarDetectiveCat.png';
import { Amplify,  Auth } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignIn, AmplifySignUp, AmplifyAuthContainer } from '@aws-amplify/ui-react';
import { Col } from 'react-bootstrap';

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    mandatorySignIn: 'enable',

  }
})

export default function App() {
  const [show, setShow] = React.useState(false);
  const [toastBody, setToastBody] = React.useState("");
  const [toastHeader, setToastHeader] = React.useState("");

  var handleClick = async (e, mode) => {
    e.preventDefault();


    // Url is to API trigger for lambda 
    const oldURL = 'https://api.contrast.ninja/' + mode;
    const userInfo = await Auth.currentUserInfo();
    const userSession = await Auth.currentSession();
    let response = await fetch(oldURL, {
      method: 'POST',
      headers: {
        'Accept': 'application/x-www-form-urlencoded',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${userSession.getIdToken().getJwtToken()}`
      },
      body: JSON.stringify({
        email: userInfo.attributes.email,
        username: userInfo.username
      })
    })
    let json = await response.json();
    setToastBody(json.message);
    if (response.status === 200) {
      setToastHeader("Success!");
    } else {
      setToastHeader("Error!");
    }
    setShow(true);

  }

  return (
    <AmplifyAuthContainer>
      <AmplifyAuthenticator usernameAlias="email">
        <AmplifySignIn headerText="Contrast Teamserver Bug Bounty" slot="sign-in" usernameAlias="email" formFields={[
          {
            type: "email",
          },
          {
            type: "password",
          },
        ]} ><div slot="header-subtitle">Reminder: This website is NOT in scope.</div></AmplifySignIn>
        <AmplifySignUp headerText="Contrast Teamserver Bug Bounty" slot="sign-up" usernameAlias="email" formFields={[
          {
            type: "email",
          },
          {
            type: "password",
          },
        ]} ><div slot="header-subtitle">Please wait a few minutes after submitting for a confirmation email. Check your spam folder if it doesn't arrive.</div></AmplifySignUp>
        <div className='app'>
          <Navbar className='nav-bar'>
            <Navbar.Brand href="https://www.contrastsecurity.com/">
              <img alt='contrastsecurity-logo'
                className='cs-logo'
                src={cslogo}
              />
            </Navbar.Brand>
            <Col></Col>
            <Col className='text-right' lg="1">
              <AmplifySignOut />
            </Col>
          </Navbar>
          <div >
            <Toast show={show} onClose={() => setShow(false)}>
              <Toast.Header>
                <strong className="mr-auto">{toastHeader}</strong>
              </Toast.Header>
              <Toast.Body>{toastBody}</Toast.Body>
            </Toast>
          </div>
          <div className='body container'>
            <form>
              <Card className="w-50">
                <Card.Img variant="top"
                  src={cat}
                />
                <Card.Body>
                  <Card.Title>
                    Contrast Security Team Server
                  </Card.Title>
                  <Card.Text>
                    Thank you for participating in the Contrast Security Bug Bounty! <br></br><br></br>
                    Click launch to have an enviroment set up for your use. After clicking you should receive an email with log in details.
                    If it doesn't arrive in a few minutes check your spam folder.<br></br><br></br>
                    If for any reason you need a new instance click delete, wait for an email confirming the deletion was successful, and then launch a new instance.
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Button className="mr-1 btn-primary" onClick={(e) => handleClick(e, 'create')}>
                    Launch
                  </Button>
                  <Button className="btn-danger" onClick={(e) => handleClick(e, 'delete')}>
                    Delete
                  </Button>
                </Card.Footer>
              </Card>
            </form>
          </div>
        </div>
      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  );

}

